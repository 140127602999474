import { defineStore } from 'pinia'
import useApiFetch from '~/composables/useApiFetch'
import type { Folder, FolderPartial } from '~/types'

interface iState {
  folders: {
    data: FolderPartial[]
    pending: boolean
  }
}

const useFolderStore = defineStore('folders', {
  state: () => {
    const state: iState = {
      folders: { data: [], pending: true },
    }

    return state
  },
  getters: {
    getFolderById: (state) => {
      return (folderId: string) => {
        return state.folders.data.find(
          (folder) => folder.id === parseInt(folderId)
        )
      }
    },
  },
  actions: {
    init({ folders }: iState) {
      this.folders = folders
    },
    // ------------------------------------------
    // FOLDERS
    // ------------------------------------------

    add({ folder }: { folder: Folder }) {
      this.folders.data.push(folder)
    },

    async fetch({ query = '' }: { query?: string } = {}) {
      const { data } = await useApiFetch(`/folders?${query}`)
      this.set({ folders: data.value })
    },

    set({ folders }: { folders: { data: Folder[] } }) {
      this.folders = { ...folders, pending: false }
    },

    remove({ folderId }: { folderId: number }) {
      this.folders.data = this.folders.data.filter(
        (folder) => folder.id !== folderId
      )
    },

    update({ folder }: { folder: FolderPartial }) {
      this.folders.data = this.folders.data.map((f) => {
        if (f.id !== folder.id) return f
        return folder
      })
    },
  },
})

export default useFolderStore
